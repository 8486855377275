import React from "react";

import Layout from "../../components/OandaComponents/LayoutOanda";
import Background from "../../components/Background";
import OandaThankyouHeader from "../../components/OandaComponents/ThankyouOanda";


const OandaContactPage = () => {
    return (
        <Layout>
            <Background themeType={"oanda"}>
                <OandaThankyouHeader
                    welcome="Ready to get started?"
                    heading=" Welcome to OANDA FX Payments"
                    message="If you’re looking for a currency exchange and global payments
           solution, you’ve come to the right place. We offer a best-in-class B2B platform and we’re here to support
            you. If you’re looking for any forex services beyond currency exchange and global payments, please get in
             touch with our general support team to find the best solution for your needs. If you want to start sending
              or receiving money across borders, let’s get started."
                    link="https://share.hsforms.com/17-NRQ6cfTK-XDJvC3Iz6Yw56z1c"
                    buttonText= "Contact Us"
                    >
            </OandaThankyouHeader>
        </Background>
</Layout>
)
    ;
};

export default (OandaContactPage);